.gridWrapper {
  font-size: var(--tm-fs-s);
  display: flex;
}
@media only screen and (min-width: 961px) {
  .gridWrapper {
    font-size: var(--tm-fs-base);
  }
}

.paddingTopL {
  padding-top: 3rem;
}
.paddingTopXL {
  padding-top: 6rem;
}
.paddingTopXXL {
  padding-top: 12rem;
}
.paddingBottomL {
  padding-bottom: 3rem;
}
.paddingBottomXL {
  padding-bottom: 6rem;
}
.paddingBottomXXL {
  padding-bottom: 12rem;
}
@media only screen and (min-width: 961px) {
  .paddingTopL {
    padding-top: 6rem;
  }
  .paddingTopXL {
    padding-top: 12rem;
  }
  .paddingTopXXL {
    padding-top: 18rem;
  }
  .paddingBottomL {
    padding-bottom: 6rem;
  }
  .paddingBottomXL {
    padding-bottom: 12rem;
  }
  .paddingBottomXXL {
    padding-bottom: 18rem;
  }
}

.marginTopL {
  margin-top: 3rem;
}
.marginTopXL {
  margin-top: 6rem;
}
.marginTopXXL {
  margin-top: 12rem;
}
.marginBottomL {
  margin-bottom: 3rem;
}
.marginBottomXL {
  margin-bottom: 6rem;
}
.marginBottomXXL {
  margin-bottom: 12rem;
}
@media only screen and (min-width: 961px) {
  .marginTopL {
    margin-top: 6rem;
  }
  .marginTopXL {
    margin-top: 12rem;
  }
  .marginTopXXL {
    margin-top: 18rem;
  }
  .marginBottomL {
    margin-bottom: 6rem;
  }
  .marginBottomXL {
    margin-bottom: 12rem;
  }
  .marginBottomXXL {
    margin-bottom: 18rem;
  }
}

.grid {
  margin: auto 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}
@media only screen and (min-width: 521px) {
  .grid {
    margin: auto 4rem;
  }
}
@media only screen and (min-width: 961px) {
  .grid {
    margin: auto;
    grid-template-columns: repeat(16, 1fr);
  }
}
@media only screen and (min-width: 1401px) {
  .grid {
    grid-template-columns: repeat(32, 1fr);
    max-width: 1920px;
  }
}

.grid > * {
  min-width: 0;
  grid-column: 1 / span 4;
}
@media only screen and (min-width: 961px) {
  .grid > * {
    grid-column: 2 / span 14;
  }
}
@media only screen and (min-width: 1401px) {
  .grid > * {
    grid-column: 2 / span 30;
  }
}
