.headerGrid {
  margin: auto 2rem 2rem;
}
@media only screen and (min-width: 961px) {
  .headerGrid {
    margin: auto;
  }
}

.headerContainer {
  display: grid;
}

.headerBackgroundWrapper {
  grid-row: 1;
  grid-column: 1;
}

.headerGridWrapper {
  grid-row: 1;
  grid-column: 1;
}

.headerGridElementContent {
  margin: auto 0;
}

.headerBackground {
  position: relative;
  height: 475px;
}
.headerBackground:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
}
@media only screen and (min-width: 961px) {
  .headerBackground:after {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 65.68%
    );
  }
}

@media only screen and (min-width: 961px) {
  .headerBackground {
    height: 550px;
  }
}
@media only screen and (min-width: 1401px) {
  .headerBackground {
    height: 700px;
  }
}

.headerContentWrapper {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 961px) {
  .headerContentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.headerContent {
  padding: 4rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 810px) {
  .headerContent {
    padding: 15rem 0 6rem;
    gap: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .headerContent {
    text-align: initial;
    max-width: 500px;
    padding: 0;
    gap: 0;
  }
}
@media only screen and (min-width: 1401px) {
  .headerContent {
    max-width: 600px;
  }
}

.headerLogo {
  position: relative;
  width: 97px;
  height: 97px;
  margin: auto;
}
@media only screen and (min-width: 961px) {
  .headerLogo {
    margin: 0;
    width: 110px;
    height: 110px;
  }
}

.headerLogo img {
  border-radius: 50%;
}

.headerTitle {
  margin: 0;
}
@media only screen and (min-width: 810px) {
  .headerTitle {
    font-size: var(--tm-fs-l);
  }
}

.headerTitle {
  margin-top: 10px;
  line-height: 30px;
  letter-spacing: 0.75px;
}
@media only screen and (min-width: 961px) {
  .headerTitle {
    margin-top: 30px;
    line-height: 50px;
    letter-spacing: 1.2px;
  }
}
@media only screen and (min-width: 1401px) {
  .headerTitle {
    letter-spacing: 1.8px;
  }
}

.headerSubTitle {
  margin-bottom: 20px;
  font-size: var(--tm-fs-s);
  letter-spacing: 0.45px;
}
@media only screen and (min-width: 961px) {
  .headerSubTitle {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 1401px) {
  .headerSubTitle {
    font-size: var(--tm-fs-base);
  }
}
.headerSubTitleMargin {
  margin-bottom: 0;
}

.headerBtn {
  align-self: center;
}
@media only screen and (min-width: 961px) {
  .headerBtn {
    align-self: initial;
  }
}

.headerLeaderboard {
  padding: 30px 15px 20px;
  border-radius: 10px;
  background: var(--tm-c-dark-gray);
  align-items: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 15px;
}
@media only screen and (min-width: 961px) {
  .headerLeaderboard {
    padding: 30px 25px;
    margin-bottom: 0;
    margin-top: 0;
    width: fit-content;
  }
}

.headerLeaderboardLogo {
  position: relative;
  width: 70px;
  height: 70px;
}
@media only screen and (min-width: 961px) {
  .headerLeaderboardLogo {
    width: 85px;
    height: 85px;
  }
}

.headerLeaderboardTitle {
  font-size: var(--tm-fs-s);
  font-weight: 400;
  margin: 0;
}
@media only screen and (min-width: 961px) {
  .headerLeaderboardTitle {
    font-size: var(--tm-fs-m);
  }
}

.leaderboardTable {
  margin: 15px 0;
  width: 100%;
  min-width: 290px;
}
@media only screen and (min-width: 961px) {
  .leaderboardTable {
    margin: 25px 0;
  }
}

.leaderboardTableBody {
  display: flex;
  flex-direction: column;
  gap: 1px;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.45px;
}

.leaderboardTableRow {
  background: rgba(65, 65, 65, 0.9);
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  grid-gap: 15px;
}

.leaderboardTableLoadingRow {
  opacity: 0.7;
}

.loadingItem {
  display: flex;
  align-items: center;
}

.tableRowLoading {
  display: inline-block;
  height: 2.5rem;
  background: var(--tm-c-dark);
  opacity: 0.3;
  width: 100%;
  border-radius: 5px;
}

.leaderboardTableRow > div:nth-child(2) {
  width: 160px;
  white-space: nowrap;
  mask-image: linear-gradient(to right, var(--tm-c-dark) 60%, rgba(0, 0, 0, 0));
}

.leaderboardTableRow:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.highlight {
  background: var(--tm-c-brand);
  display: inline-block;
  width: 35px;
  border-radius: 3px;
  text-align: center;
}
.highlightPar {
  background: #8e8e8e;
}
.highlightOverPar {
  background: var(--tm-c-dark);
}
@media only screen and (min-width: 961px) {
  .highlight {
    width: 45px;
  }
}

.leaderboardTableHeader {
  background: var(--colors-leaderboard-70, #212121);
  font-size: 1.2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px 20px 5px 15px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 3fr 1fr 1fr;
}
@media only screen and (min-width: 961px) {
  .leaderboardTableHeader {
    font-size: var(--tm-fs-s);
  }
}
