.tournamentOverviewGridWrapper {
  margin-top: 30px;
}
@media only screen and (min-width: 961px) {
  .tournamentOverviewGridWrapper {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1401px) {
  .tournamentOverviewGridWrapper {
    margin-top: 110px;
  }
}

.blogPostsTitle {
  font-size: var(--tm-fs-m);
  margin-bottom: 10px;
  text-align: center;
}

.tournamentOverviewLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 4rem;
}
@media only screen and (min-width: 961px) {
  .tournamentOverviewLayout {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1401px) {
  .tournamentOverviewLayout {
    gap: 3rem;
    margin-top: 2.5rem;
  }
}

.buttonMargin {
  margin: 20px auto 0;
}
@media only screen and (min-width: 961px) {
  .buttonMargin {
    margin: 55px auto 0;
  }
}
