.tabsHeaderLogoWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.tabLogoWrapper {
  position: relative;
  width: 85px;
  height: 85px;
  justify-content: center;
  align-items: center;
  display: flex;
}
@media only screen and (min-width: 961px) {
  .tabLogoWrapper {
    width: 136px;
    height: 136px;
  }
}

.orangeBorder {
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(21, 20, 20, 0) 0%,
    rgba(236, 105, 26, 1) 50%,
    rgba(21, 20, 20, 0) 100%
  );
  margin: 3rem 0;
}

.tabLogoTitle {
  font-size: var(--tm-fs-m);
  line-height: 1.2;
  letter-spacing: 0.75px;
  margin-top: 1.5rem;
  margin-bottom: 0;
}
@media only screen and (min-width: 961px) {
  .tabLogoTitle {
    font-size: var(--tm-fs-l);
    line-height: 1.25;
    letter-spacing: 1.2px;
    margin-top: 3rem;
  }
}

.tabs {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 2rem 0 8rem;
  padding: 0;
  font-size: var(--tm-fs-s);
  line-height: 1.6;
  letter-spacing: 0.45px;
}

.tab {
  padding: 1rem 2.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--tm-c-dark-gray);
  border-radius: 6px;
}
@media only screen and (min-width: 961px) {
  .tab:hover {
    background-color: var(--tm-c-base-dark);
  }
}

.tabActive {
  background-color: var(--tm-c-base-dark);
}

.tabsDropdownWrapper {
  display: flex;
  justify-content: center;
}

.tabsDropdown {
  margin-bottom: 30px;
  max-width: 330px;
}
@media only screen and (min-width: 961px) {
  .tabsDropdown {
    margin-bottom: 55px;
  }
}
