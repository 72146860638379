.blogPostCard {
  border-radius: 10px;
  overflow: hidden;
  background: #1b1b1b;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.blogPostCardLarge {
  position: relative;
  display: flex;
  flex-direction: column;
  background: none;
}
@media only screen and (min-width: 810px) {
  .blogPostCardLarge {
    flex-direction: row;
  }
}

.cardImage {
  position: relative;
  height: 168px;
}
@media only screen and (min-width: 810px) {
  .cardImage {
    height: 198px;
  }
}
@media only screen and (min-width: 1401px) {
  .cardImage {
    height: 292px;
  }
}

.blogPostCardLarge .cardImage {
  width: 100%;
  height: 265px;
  border-radius: 10px;
  overflow: hidden;
}
.blogPostCardLarge .cardImage:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
@media only screen and (min-width: 810px) {
  .blogPostCardLarge .cardImage {
    height: 433px;
    flex: 3 1;
  }
}
@media only screen and (min-width: 1401px) {
  .blogPostCardLarge .cardImage {
    height: 630px;
  }
}

.cardContent {
  padding: 25px 30px;
  font-weight: 400;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 810px) {
  .cardContent {
    flex: 1;
  }
}

.blogPostCardLarge .cardContent {
  padding: 0 3.5rem;
  position: absolute;
  margin: 0;
  bottom: 13px;
  flex: unset;
}
@media only screen and (min-width: 810px) {
  .blogPostCardLarge .cardContent {
    position: relative;
    flex: 2 1;
    margin: auto 0 auto -80px;
    align-items: flex-start;
    padding: 0;
  }
}

.blogPostCardLarge .cardContent .cardCta {
  margin-top: 25px;
}

.cardContent h1 {
  margin: 0;
  font-size: var(--tm-fs-s);
  line-height: 25px;
  letter-spacing: 0.45px;
}

.cardContent h2 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.36px;
}

.blogPostCardLarge .cardContent h2 {
  margin: 0;
  font-size: var(--tm-fs-s);
  line-height: 25px;
  letter-spacing: 0.45px;
}
@media only screen and (min-width: 810px) {
  .cardContent h2 {
    font-size: var(--tm-fs-base);
    line-height: 30px;
    letter-spacing: 0.6px;
  }
}
@media only screen and (min-width: 1401px) {
  .blogPostCardLarge .cardContent h2 {
    font-size: var(--tm-fs-base);
    line-height: 30px;
    letter-spacing: 0.6px;
  }
}

.blogPostCardLarge .cardContent h1 {
  font-size: var(--tm-fs-m);
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.75px;
}
@media only screen and (min-width: 961px) {
  .blogPostCardLarge .cardContent h1 {
    font-size: var(--tm-fs-l);
    line-height: 50px;
    letter-spacing: 1.2px;
  }
}
@media only screen and (min-width: 1401px) {
  .blogPostCardLarge .cardContent h1 {
    font-size: 60px;
    line-height: 65px; /* 108.333% */
    letter-spacing: 1.8px;
  }
}

.blogPostCardParagraphBtn {
  display: flex;
  flex-direction: column;
  padding: 15px 35px 0;
}
.blogPostCardParagraph {
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.45px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 961px) {
  .blogPostCardParagraph {
    margin-bottom: 25px;
  }
}

.cardCta {
  align-self: center;
}

/* LOADING state */
.blogPostCardLoading .cardImage {
  background: var(--tm-c-dark-gray);
}

.blogPostCardLoading .loadingMetaTitle,
.blogPostCardLoading .loadingTitle,
.blogPostCardLoading .loadingCta {
  background: var(--tm-c-dark-gray);
}
.blogPostCardLoading .loadingMetaTitle {
  height: var(--tm-fs-s);
  width: 200px;
  margin-bottom: 1.5rem;
}
.blogPostCardLoading .loadingTitle {
  height: var(--tm-fs-base);
  margin-bottom: 1rem;
}
.blogPostCardLoading .loadingCta {
  height: var(--tm-fs-base);
  margin-top: 4rem;
  width: 200px;
}
