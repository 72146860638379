.dropdown {
  position: relative;
  width: 100%;
  max-width: 330px;
  min-height: 45px;
  font-size: var(--tm-fs-s);
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.45px;
  outline: none;
  padding: 10px 10px 10px 15px;
  background: #343434;
  border: none;
  color: var(--tm-c-base-light);
  cursor: pointer;
  border-radius: 10px;
}

.dropdownPlaceholder {
  display: flex;
  justify-content: space-between;
}

.dropdown nav {
  background: #212121;
  position: absolute;
  left: 0;
  right: 0;
  top: 45px;
  z-index: 2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 0;
  overflow: hidden;
}

.dropdown nav > * {
  height: 45px;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 15px;
}

.dropdownItem {
  height: 45px;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 15px;
}

.dropdownItemMain {
  background: #343434;
}

.dropdownItemActive {
  background: #4d4d4d;
}

@media only screen and (min-width: 961px) {
  .dropdownItem:hover {
    background: #414141;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}
