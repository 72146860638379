.leaderboardTableWrapper {
  padding-bottom: 8rem;
}

.leaderboardTable {
  background: #2c2c2c;
  border-radius: 10px;
  overflow: hidden;
  min-height: 800px;
}

.tableHeader {
  background-color: var(--tm-c-base-dark);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  gap: 0.5rem;
}
@media only screen and (min-width: 861px) {
  .tableHeader {
    gap: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .tableHeader {
    padding: unset;
  }
}

.tableHeaderCell {
  padding: 1.5rem 0;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}
.tableHeaderCell:nth-of-type(3) {
  flex: 4;
  text-align: left;
}
.tableHeaderCell:nth-of-type(4) {
  flex: 2;
}
.leaderboardTableMain .tableHeaderCell:nth-of-type(3) {
  flex: 1;
  text-align: center;
}
.leaderboardTableMain .tableHeaderCell:nth-of-type(4) {
  flex: 5;
  max-width: 130px;
  text-align: left;
}
@media only screen and (min-width: 461px) {
  .leaderboardTableMain .tableHeaderCell:nth-of-type(4) {
    max-width: 160px;
  }
}
@media only screen and (min-width: 561px) {
  .leaderboardTableMain .tableHeaderCell:nth-of-type(4) {
    max-width: 180px;
  }
}
@media only screen and (min-width: 621px) {
  .leaderboardTableMain .tableHeaderCell:nth-of-type(4) {
    max-width: 220px;
  }
}
@media only screen and (min-width: 761px) {
  .leaderboardTableMain .tableHeaderCell:nth-of-type(4) {
    max-width: unset;
  }
}
.leaderboardTableMain .tableHeaderCell:nth-of-type(5) {
  flex: 1;
}
@media only screen and (min-width: 861px) {
  .tableHeaderCell:nth-of-type(3) {
    flex: 5;
  }
  .tableHeaderCell:nth-of-type(4) {
    flex: 1;
  }
  .tableHeaderCell:last-of-type {
    padding-right: 1.5rem;
  }
}
@media only screen and (min-width: 961px) {
  .tableHeaderCell {
    font-size: var(--tm-fs-s);
  }
}

.thCellLabel {
  display: none;
}
@media only screen and (min-width: 961px) {
  .thCellLabel {
    display: block;
  }
}

.tableRowGroup {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s;
}
.tableRowGroupHidden {
  opacity: 0.2;
}
.leaderboardTableMain .tableRowGroup {
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
}
@media only screen and (min-width: 961px) {
  .leaderboardTableMain .tableRowGroup:hover {
    background-color: #171717;
  }
}
.tableRowGroupPostFirstKind {
  border-top: 1px solid var(--tm-c-base-dark);
}
.leaderboardTableCTP .tableRowGroup,
.leaderboardTableLD .tableRowGroup {
  border-bottom: 1px solid var(--tm-c-base-dark);
}
.leaderboardTableCTP .tableRowGroup:last-of-type,
.leaderboardTableLD .tableRowGroup:last-of-type {
  border-bottom: none;
}

.tableRowGroup > .tableRow {
  padding: 0 1rem;
}
@media only screen and (min-width: 961px) {
  .tableRowGroup > .tableRow {
    padding: unset;
  }
}

.tableRowGroup .tableRowGroupInner {
  overflow: hidden;
  cursor: default;
}
.tableRowGroupActive .tableRowGroupInner {
  background: #212121;
}
.tableRowGroupCTP .tableRowGroupInner {
  opacity: 0;
  height: 0;
}
.tableRowGroupCTP .tableRowGroupInner > * {
  background: #343434;
  margin: 2rem;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 861px) {
  .tableRowGroupCTP .tableRowGroupInner > * {
    background: unset;
    margin: unset;
    border-radius: unset;
    overflow: unset;
  }
}

.tableRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
}
@media only screen and (min-width: 861px) {
  .tableRow {
    gap: 1rem;
  }
}

.tableRowCell {
  padding: 1.5rem 0;
  text-align: center;
  flex: 1;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media only screen and (min-width: 861px) {
  .tableRowCell {
    font-size: var(--tm-fs-s);
  }
  .tableRowCell:last-of-type {
    padding-right: 1.5rem;
  }
}
.tableRowCellOverflow {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 90px;
  width: 40px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(44, 44, 44, 1) 100%
  );
}
@media only screen and (min-width: 461px) {
  .tableRowCellOverflow {
    left: 120px;
  }
}
@media only screen and (min-width: 561px) {
  .tableRowCellOverflow {
    left: 140px;
  }
}
@media only screen and (min-width: 621px) {
  .tableRowCellOverflow {
    left: 180px;
  }
}
@media only screen and (min-width: 761px) {
  .leaderboardTableMain .tableRowGroup:hover .tableRowCellOverflow,
  .tableRowCellOverflow {
    display: none;
  }
}
.tableRowCell:nth-of-type(3) {
  flex: 4;
  text-align: left;
  justify-content: flex-start;
}
.tableRowCell:nth-of-type(4) {
  flex: 2;
}
@media only screen and (min-width: 861px) {
  .tableRowCell:nth-of-type(3) {
    flex: 5;
  }
  .tableRowCell:nth-of-type(4) {
    flex: 1;
  }
}
.leaderboardTableMain .tableRowCell:nth-of-type(3) {
  flex: 1;
  justify-content: center;
  text-align: center;
}
.leaderboardTableMain .tableRowCell:nth-of-type(4) {
  flex: 5;
  max-width: 130px;
  overflow: hidden;
  text-align: left;
  justify-content: flex-start;
  white-space: nowrap;
}
@media only screen and (min-width: 461px) {
  .leaderboardTableMain .tableRowCell:nth-of-type(4) {
    max-width: 160px;
  }
}
@media only screen and (min-width: 561px) {
  .leaderboardTableMain .tableRowCell:nth-of-type(4) {
    max-width: 180px;
  }
}
@media only screen and (min-width: 621px) {
  .leaderboardTableMain .tableRowCell:nth-of-type(4) {
    max-width: 220px;
  }
}
@media only screen and (min-width: 761px) {
  .leaderboardTableMain .tableRowCell:nth-of-type(4) {
    max-width: unset;
  }
}
@media only screen and (min-width: 961px) {
  .leaderboardTableMain .tableRowCell:nth-of-type(4) {
    overflow: unset;
    white-space: unset;
  }
}
.leaderboardTableMain .tableRowCell:nth-of-type(5) {
  flex: 1;
}

.tableRowGroupCTP .tableRowGroupInner > * > * > * {
  flex: 1;
  text-align: center;
  justify-content: center;
}

.tableRowGroupInner .tableHeader {
  padding: unset;
}

.highlight {
  background: var(--tm-c-brand);
  display: inline-block;
  width: 35px;
  border-radius: 3px;
}
.highlightPar {
  background: #8e8e8e;
}
.highlightOverPar {
  background: var(--tm-c-dark);
}
@media only screen and (min-width: 961px) {
  .highlight {
    width: 45px;
  }
}

.loading {
  display: inline-block;
  height: 2.5rem;
  background: var(--tm-c-dark);
  opacity: 0.3;
  width: 60%;
  border-radius: 10px;
}

.noData {
  display: block;
  background: var(--tm-c-dark);
  opacity: 0.3;
  height: 2.5rem;
  margin: 2rem;
  width: 100%;
}

.paginationContainer p {
  font-size: var(--tm-fs-s);
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 1rem auto 0;
}
@media only screen and (min-width: 961px) {
  .paginationContainer p {
    margin: 1rem 0 0 auto;
  }
}

.infoHeading {
  color: var(--tm-c-dark);
  opacity: 0.6;
  font-size: 2.5rem;
  margin: 16rem 4rem 0;
  text-align: center;
}

.playerNationality {
  font-size: 1.6rem;
}
@media only screen and (min-width: 961px) {
  .playerNationality {
    font-size: 2rem;
  }
}
