.imageComponent {
  position: relative;
  height: 20rem;
}
@media only screen and (min-width: 961px) {
  .imageComponent {
    height: 40rem;
  }
}
@media only screen and (min-width: 1601px) {
  .imageComponent {
    height: 60rem;
  }
}
