.cta {
  color: var(--tm-c-brand);
  font-size: var(--tm-fs-s);
}
@media only screen and (min-width: 1401px) {
  .cta {
    font-size: 1.7rem;
  }
}

.ctaButton:disabled {
  opacity: 0.3;
  cursor: default;
}

.ctaButton {
  font-size: var(--tm-fs-s);
  cursor: pointer;
  padding: 0 4rem;
  text-align: center;
  border-radius: 6px;
  width: fit-content;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
@media only screen and (min-width: 1401px) {
  .ctaButton {
    font-size: 1.7rem;
    height: 45px;
  }
}

.primary {
  color: var(--tm-c-base-light);
  background: var(--tm-c-brand);
}

.outlined {
  border: 2px solid var(--tm-c-brand);
  background-color: transparent;
}

.secondary {
  color: var(--tm-c-base-light);
  background: #343434;
}
