.swiperWrapper {
  padding: 4rem 0;
}

.playerSlide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px !important;
  border-radius: 10px;
}
@media only screen and (min-width: 1401px) {
  .playerSlide {
    width: 320px !important;
  }
}

.playerSlideMedia {
  height: 170px;
  position: relative;
}
@media only screen and (min-width: 1401px) {
  .playerSlideMedia {
    height: 215px;
  }
}
.playerSlideMedia > span {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.playerSlideMedia:after {
  content: "";
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 55px;
  background: #090909;
  filter: blur(25px);
}

.playerSlideContent {
  position: relative;
  bottom: 10px;
  width: 100%;
  height: 210px;
  border-radius: 10px;
  background: linear-gradient(180deg, var(--tm-c-base-dark) 0%, #282828 100%);
  padding: 5px 15px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media only screen and (min-width: 1401px) {
  .playerSlideContent {
    height: 250px;
  }
}

.seasonNumber {
  color: #767676;
  font-size: 1.2rem;
  letter-spacing: 0.36px;
}
@media only screen and (min-width: 1401px) {
  .seasonNumber {
    font-size: var(--tm-fs-s);
    letter-spacing: 0.45px;
  }
}

.playerTitle {
  font-size: var(--tm-fs-s);
  letter-spacing: 0.45px;
}
@media only screen and (min-width: 1401px) {
  .playerTitle {
    font-size: var(--tm-fs-base);
    letter-spacing: 0.6px;
  }
}

.playerCountry {
  font-size: 1.2rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 1401px) {
  .playerCountry {
    font-size: var(--tm-fs-s);
  }
}

.playerResults {
  font-size: 1.2rem;
  letter-spacing: 0.36px;
  margin-top: 2.5rem;
}
@media only screen and (min-width: 1401px) {
  .playerResults {
    font-size: var(--tm-fs-s);
    letter-spacing: 0.45px;
    margin-top: 3.5rem;
  }
}

.playerResultsInfo {
  display: flex;
  justify-content: space-between;
}
