.teaserHeadline {
  text-align: center;
  margin: 0 0 2rem;
}
@media only screen and (min-width: 961px) {
  .teaserHeadline {
    margin: 0 0 3rem;
  }
}

.teaserRowGridWrapper {
  background: var(--tm-c-dark-gray);
  padding: 6rem 0;
}

.teaserRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media only screen and (min-width: 810px) {
  .teaserRow {
    flex-direction: row;
    gap: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .teaserRow {
    gap: 2rem;
  }
}

.teaserRowItem {
  width: 250px;
  height: 130px;
  border-radius: 10px;
  background: linear-gradient(180deg, #1c1c1c 0%, #2a2a2a 100%);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
}

@media only screen and (min-width: 961px) {
  .teaserRowItem {
    width: 300px;
    height: 150px;
  }
}
@media only screen and (min-width: 1601px) {
  .teaserRowItem {
    width: 360px;
    height: 200px;
  }
}

.teaserRowInfo,
.teaserRowLabel {
  font-size: var(--tm-fs-s);
  margin: 0;
}
@media only screen and (min-width: 961px) {
  .teaserRowInfo,
  .teaserRowLabel {
    font-size: 1.7rem;
  }
}
@media only screen and (min-width: 961px) {
  .teaserRowInfo,
  .teaserRowLabel {
    font-size: var(--tm-fs-base);
  }
}

.teaserRowLabel {
  opacity: 0.5;
}
