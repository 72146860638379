.prizePoolTableWrapper {
  display: grid;
  grid-template-columns: 1fr;
  background: var(--tm-c-dark-gray);
}
.prizePoolTableWrapper > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.bgGraphic {
  opacity: 0.6;
  pointer-events: none;
}
@media only screen and (min-width: 961px) {
  .bgGraphic {
    padding: 6rem 0;
  }
}

.bgGraphic > * {
  margin-top: 0;
}

.bgGraphic > *,
.bgGraphic > * > *,
.bgGraphic .bgGraphicImage {
  height: 70%;
}
@media only screen and (min-width: 961px) {
  .bgGraphic > *,
  .bgGraphic > * > *,
  .bgGraphic .bgGraphicImage {
    height: 100%;
  }
}

.bgGraphicImage {
  position: relative;
}

.prizePoolTable {
  display: flex;
  flex-direction: column;
  margin: 6rem 0;
}
@media only screen and (min-width: 961px) {
  .prizePoolTable {
    margin: 16rem 0;
    flex-direction: row;
  }
}

.prizePoolTableTabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 4rem;
  gap: 2rem;
}
@media only screen and (min-width: 961px) {
  .prizePoolTableTabs {
    gap: 6rem;
    width: 20%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: unset;
    margin-bottom: unset;
  }
}

.prizePoolTableTab {
  padding: 1rem 0;
  cursor: pointer;
  color: var(--tm-c-base-light);
  opacity: 0.2;
  transition: opacity 0.3s;
  font-size: var(--tm-fs-base);
}
@media only screen and (min-width: 961px) {
  .prizePoolTableTab {
    width: fit-content;
    font-size: var(--tm-fs-m);
  }
}
.prizePoolTableTabActive {
  opacity: 1;
}

.prizePoolContainer {
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem;
}
@media only screen and (min-width: 961px) {
  .prizePoolContainer {
    padding: unset;
    width: 80%;
  }
}
.prizePoolContainer > * {
  grid-row-start: 1;
  grid-column-start: 1;
}
