.gridWrapper {
  padding-top: 5rem;
  padding-bottom: 6rem;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    linear-gradient(0deg, rgba(65, 65, 65, 0.5), rgba(65, 65, 65, 0.5));
}

@media only screen and (min-width: 961px) {
  .gridWrapper {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
}

.title {
  text-align: center;
  margin: 0 0 2rem;
}

@media only screen and (min-width: 961px) {
  .title {
    text-align: center;
    margin: 0 0 4rem;
  }
}

.imgRow {
  display: flex;
  gap: 2rem;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.imgWrapper {
  position: relative;
  width: 90px;
  height: 90px;
}

@media only screen and (min-width: 810px) {
  .imgContainer {
    flex-direction: row;
    justify-content: center;
    gap: 3.5rem;
  }

  .imgRow {
    display: flex;
    gap: 3.5rem;
  }

  .imgWrapper {
    width: 110px;
    height: 110px;
  }
}

@media only screen and (min-width: 1920px) {
  .imgContainer {
    gap: 6.5rem;
  }

  .imgRow {
    display: flex;
    gap: 6.5rem;
  }

  .imgWrapper {
    width: 160px;
    height: 160px;
  }
}

.img {
  border-radius: 50%;
}
