.leaderboardControls {
  margin-bottom: 1rem;
  display: grid;
  justify-content: center;
  gap: 1rem;
}
@media only screen and (min-width: 810px) {
  .leaderboardControls {
    gap: 2rem;
  }
}
@media only screen and (min-width: 1201px) {
  .leaderboardControls {
    justify-content: space-between;
    grid-auto-flow: column;
    align-items: center;
  }
}

.actionBar {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1201px) {
  .actionBar {
    flex-direction: row;
  }
}

.playNowCheckbox label {
  gap: 1rem;
}

.playNowCheckbox {
  display: flex;
  width: 330px;
}
@media only screen and (min-width: 810px) {
  .playNowCheckbox {
    width: 100%;
  }
}

.filterBtnWrapper {
  display: flex;
}

.selectWrapper,
.searchFilter {
  width: 100%;
}
@media only screen and (min-width: 364px) {
  .selectWrapper,
  .searchFilter {
    width: 330px;
  }
}

.searchFilter {
  display: flex;
  gap: 1rem;
}
@media only screen and (min-width: 961px) {
  .searchFilter {
    width: 100%;
    max-width: 400px;
  }
}

@media only screen and (min-width: 1201px) {
  .ctpDropdown {
    width: 180px !important;
  }
}

.selectWrapper {
  display: flex;
  gap: 1rem;
  flex-direction: row;
}

.filterWrapper,
.searchWrapper {
  display: flex;
  align-items: center;
  height: 45px;
  font-size: var(--tm-fs-s);
  outline: none;
  background: #343434;
  border: none;
  color: var(--tm-c-base-light);
  cursor: pointer;
  border-radius: 10px;
}

.filterWrapper {
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  white-space: nowrap;
}

.searchWrapper {
  width: 220px;
  padding: 1rem 1rem 1rem 1.5rem;
  justify-content: space-between;
  position: relative;
}
@media only screen and (min-width: 1201px) {
  .searchWrapper {
    width: 330px;
  }
}

.searchWrapper input {
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: var(--tm-fs-s);
  width: 100%;
}

.searchWrapper input:focus-visible {
  outline: none;
}

.icon {
  position: relative;
  height: 100%;
}
.icon svg {
  display: flex;
  margin: auto;
  height: 100%;
}

.filterPanel {
  position: fixed;
  bottom: 0;
  z-index: 5;
  border-radius: 20px 0px 0px 20px;
  background: var(--tm-c-dark-gray);
  width: 100%;
  opacity: 0;
  height: calc(100vh - 100px);
  overflow: hidden;
}
@media only screen and (min-width: 961px) {
  .filterPanel {
    height: 100vh;
    width: 475px;
    top: 0;
    right: 0;
  }
}

.filterPanelContainer {
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filterHeader {
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterHeaderIcon {
  display: flex;
  position: absolute;
  right: auto;
  cursor: pointer;
}
@media only screen and (min-width: 961px) {
  .filterHeaderIcon {
    right: 30px;
  }
}

.filterHeaderIcon svg {
  width: 30px;
  height: 30px;
}

.leaderboardFilters {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 2rem 0;
  font-size: var(--tm-fs-s);
}

.filterPanelBtns {
  height: 95px;
  background: var(--colors-Items-secondary, #141414);
  box-shadow: 0px -25px 105px 10px #090909;
  position: sticky;
  bottom: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
@media only screen and (min-width: 961px) {
  .filterPanelBtns {
    height: 120px;
  }
}

.filterContent {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  font-size: 1.7rem;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media only screen and (min-width: 1401px) {
  .filterContent {
    padding: 10rem 0;
    gap: 5.5rem;
  }
}

.nationalitiesFilter {
  padding-bottom: 4rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: calc(100vh - 200px);
  min-height: 200px;
  overflow-y: auto;
}
@media only screen and (min-width: 810px) {
  .nationalitiesFilter {
    padding-bottom: 8rem;
  }
}
@media only screen and (min-width: 1401px) {
  .nationalitiesFilter {
    padding-bottom: 2rem;
  }
}

.filterGroupTitle {
  font-size: 1.2rem;
  letter-spacing: 0.36px;
  opacity: 0.5;
}

.disabled {
  opacity: 0.4;
  cursor: default;
}
