.heroHeader {
  height: 80vh;
  margin-top: -11rem;
  position: relative;
  background: var(--tm-c-dark);
  z-index: -1;
}
@media only screen and (min-width: 961px) {
  .heroHeader {
    height: calc(100vh + 1rem);
    margin-top: -9rem;
  }
}

.heroHeaderBgGraphics {
  display: none;
}
@media only screen and (min-width: 961px) {
  .heroHeaderBgGraphics {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/bg_graphics/arrows_down.svg");
    background-repeat: no-repeat;
    background-position: 120% 110%;
    background-size: 50%;
    width: 100%;
    height: 100%;
    opacity: 0.2;
  }
}

.bgLayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.bgLayerGrid_1 {
  margin-top: 200px;
}
.bgLayerGrid_2 {
  margin-top: 300px;
}

.imgHeadline {
  text-align: center;
  position: relative;
}
@media only screen and (min-width: 961px) {
  .imgHeadline {
    grid-column: 5 / span 8;
  }
}
@media only screen and (min-width: 1401px) {
  .imgHeadline {
    grid-column: 10 / span 14;
  }
}

.headline {
  font-weight: 400;
  font-size: var(--tm-fs-base);
  text-align: center;
  margin-top: 15vh;
}

.scrollIcon {
  width: 90px;
  height: 40px;
  position: relative;
  margin: 4rem auto auto;
}
