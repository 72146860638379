.prizePool {
  opacity: 0;
  transition: opacity 0.4s;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 961px) {
  .prizePool {
    flex-direction: row;
  }
}
.prizePoolActive {
  opacity: 1;
  z-index: 1;
}

.prizePoolTotal {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: space-between;
  position: relative;
  padding: 6rem 0;
}
@media only screen and (min-width: 961px) {
  .prizePoolTotal {
    padding: 0 0 0 8.25%;
    width: 40%;
  }
}

.prizePoolBorder {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(21, 20, 20, 0) 0%,
    rgba(236, 105, 26, 1) 50%,
    rgba(21, 20, 20, 0) 100%
  );
}
.prizePoolBorderLeft {
  top: 0;
  left: 0;
}
.prizePoolBorderRight {
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 961px) {
  .prizePoolBorder {
    top: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(21, 20, 20, 0) 0%,
      rgba(236, 105, 26, 1) 50%,
      rgba(21, 20, 20, 0) 100%
    );
  }
  .prizePoolBorderRight {
    left: unset;
    right: 0;
  }
}

.prizePoolTotalItem {
  display: flex;
  gap: 2rem;
}
.prizePoolTotalItem > * {
  width: 100%;
}
.prizePoolTotalItem input {
  background: #202020;
  border: none;
  border-radius: 6px;
  padding: 1rem;
  color: var(--tm-c-base-light);
  font-size: 2rem;
  font-family: var(--tm-ff);
  max-width: 150px;
  margin-top: 1rem;
}

.prizePoolTotalItemGray {
  opacity: 0.6;
}

.totalItemInfo {
  transform: translateY(-30px);
  opacity: 0;
  transition: transform 0.4s, opacity 0.2s;
}
.prizePoolActive .totalItemInfo {
  opacity: 1;
  transform: translateY(0);
}

.prizePoolSideKicks {
  display: grid;
  row-gap: 4rem;
  padding: 6rem 0 0;
}
@media only screen and (min-width: 961px) {
  .prizePoolSideKicks {
    padding: 0 0 0 8.25%;
    width: 60%;
  }
}

.sideKickItem {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.sideKickItem:nth-of-type(1) {
  grid-row: 1;
  grid-column: 1;
}
.sideKickItem:nth-of-type(2) {
  grid-row: 1;
  grid-column: 2;
}
.sideKickItem:nth-of-type(3) {
  grid-row: 2;
  grid-column: 1;
}
.sideKickItem:nth-of-type(4) {
  grid-row: 2;
  grid-column: 2;
}
.sideKickItem:nth-of-type(5) {
  grid-row: 3;
  grid-column: 1;
}
.sideKickItem:nth-of-type(6) {
  grid-row: 3;
  grid-column: 2;
}

.sideKickItem h3 {
  margin: 0;
  font-size: var(--tm-fs-s);
  font-weight: 300;
}
@media only screen and (min-width: 961px) {
  .sideKickItem h3 {
    font-size: var(--tm-fs-base);
  }
}

.sideKickItemInfo {
  opacity: 0.6;
  display: flex;
  gap: 2rem;
  transform: translateX(30px);
  opacity: 0;
  width: 80%;
}
.sideKickItemInfo > * {
  width: 100%;
}
.prizePoolActive .sideKickItemInfo {
  opacity: 0.6;
  transform: translateX(0);
  transition: transform 0.4s, opacity 0.3s;
}
.sideKickItemInfo:nth-of-type(1) {
  transition-delay: 0.1s;
}
.sideKickItemInfo:nth-of-type(2) {
  transition-delay: 0.3s;
}
.sideKickItemInfo:nth-of-type(3) {
  transition-delay: 0.5s;
}
.sideKickItemInfo:nth-of-type(4) {
  transition-delay: 0.7s;
}
.sideKickItemInfo:nth-of-type(5) {
  transition-delay: 0.9s;
}
@media only screen and (min-width: 961px) {
  .sideKickItemInfo {
    width: 50%;
  }
}

/* Chrome, Safari, Edge, Opera */
.prizePoolTotalItem input::-webkit-outer-spin-button,
.prizePoolTotalItem input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.prizePoolTotalItem input[type="number"] {
  -moz-appearance: textfield;
}
