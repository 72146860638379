.tournamentCardWrapper {
  width: 100%;
}
@media only screen and (min-width: 810px) {
  .tournamentCardWrapper {
    max-width: 500px;
  }
}

.tournamentCardLink:hover {
  opacity: 1;
}

.tournamentCard {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tournamentCardBackground {
  position: relative;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 961px) {
  .tournamentCardBackground {
    height: 370px;
  }
}
@media only screen and (min-width: 1401px) {
  .tournamentCardBackground {
    height: 462px;
  }
}

.tournamentCardImg {
  width: 100%;
  height: 100%;
  position: relative;
}
.tournamentCardImg:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.tournamentCardContent {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 23px 26px;
}
@media only screen and (min-width: 1401px) {
  .tournamentCardContent {
    padding: 30px 33px 43px;
  }
}

.tournamentCardHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.tournamentCardLogoWrapper {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: relative;
  border: 1px solid var(--tm-c-base-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 961px) {
  .tournamentCardLogoWrapper {
    width: 89px;
    height: 89px;
  }
}
.tournamentLogo {
  width: 85%;
  height: 85%;
  position: relative;
}

.tournamentCardLogo {
  width: 75px;
  height: 75px;
  background-color: var(--tm-c-base-light);
  border-radius: 50%;
}
@media only screen and (min-width: 961px) {
  .tournamentCardLogo {
    width: 89px;
    height: 89px;
  }
}

.tournamentCardTitle {
  font-size: var(--tm-fs-base);
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-top: 15px;
  text-align: center;
}
@media only screen and (min-width: 961px) {
  .tournamentCardTitle {
    margin-top: 24px;
  }
}
@media only screen and (min-width: 1401px) {
  .tournamentCardTitle {
    font-size: var(--tm-fs-m);
    letter-spacing: 0.75px;
  }
}

.tournamentCardBoxes {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 100%;
  gap: 10px;
}
@media only screen and (min-width: 810px) {
  .tournamentCardBoxes {
    width: 80%;
  }
}
@media only screen and (min-width: 961px) {
  .tournamentCardBoxes {
    width: 100%;
  }
}

.tournamentCardBox {
  position: relative;
  border-radius: 10px;
  background: rgba(20, 20, 20, 0.5);
  padding: 26px 10px;
  text-align: center;
  overflow: hidden;
}

.tournamentCardArrowsBg {
  position: absolute;
  top: 0;
  left: -10px;
  height: 100%;
  width: 100%;
  background-size: auto 100%;
  overflow: hidden;
}
@media only screen and (min-width: 961px) {
  .tournamentCardArrowsBg {
    left: 0;
  }
}

.cardBoxTitle {
  font-size: var(--tm-fs-s);
  line-height: 1.6;
  letter-spacing: 0.45px;
  margin: 0;
}

.cardBoxText {
  font-size: var(--tm-fs-base);
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin: 0;

  &.cta {
    color: var(--tm-c-brand);
    background: none;
    border: none;
    padding: 0;
  }
}

.tournamentInfo {
  position: relative;
  margin-top: 7px;
}
.tournamentInfo .cardBoxText {
  font-size: var(--tm-fs-s);
}
@media only screen and (min-width: 961px) {
  .tournamentInfo .cardBoxText {
    font-size: var(--tm-fs-base);
  }
}

/* LOADING state */
.tournamentCardLoading .cardImage {
  background: var(--tm-c-dark-gray);
}

.tournamentCardLoading .loadingMetaTitle,
.tournamentCardLoading .loadingTitle,
.tournamentCardLoading .loadingCta {
  background: var(--tm-c-dark-gray);
}
.tournamentCardLoading .loadingMetaTitle {
  height: var(--tm-fs-s);
  width: 200px;
  margin-bottom: 1.5rem;
}
.tournamentCardLoading .loadingTitle {
  height: var(--tm-fs-base);
  margin-bottom: 1rem;
}
.tournamentCardLoading .loadingCta {
  height: var(--tm-fs-base);
  margin-top: 4rem;
  width: 200px;
}
