.prizeListTitle {
  text-align: center;
}

.prizeListSubTitleWrapper {
  padding: 0 5rem;
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.prizeListSubTitle {
  display: flex;
  gap: 2rem;
  margin: 0;
}
.prizeListSubTitle > * {
  display: block;
  width: 100%;
  font-weight: 400;
}

.prizeList {
  list-style: none;
  margin: 0;
  padding: 0;
  background: var(--tm-c-dark-gray);
  border-radius: 4px;
  overflow: hidden;
}
.prizeListItem {
  display: flex;
}
.prizeListItem:nth-of-type(even) {
  background: rgba(65, 65, 65, 0.2);
}
.prizeListItem:nth-of-type(1) {
  background: var(--tm-c-base-dark);
}
.prizeListItem > * {
  width: 100%;
  display: block;
  text-align: center;
  padding: 1rem 0;
  font-size: var(--tm-fs-base);
}

.selectWrapper {
  display: flex;
  justify-content: center;
}
