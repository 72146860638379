.countdownGridWrapper {
  padding: 1.5rem 0;
  background: linear-gradient(
    90deg,
    var(--tm-c-brand-gradient) 0%,
    var(--tm-c-brand) 100%
  );
}
@media only screen and (min-width: 810px) {
  .countdownGridWrapper {
    padding: 1rem 0;
  }
}
@media only screen and (min-width: 961px) {
  .countdownGridWrapper {
    padding: 0.8rem 0;
  }
  .countdownVariant--right {
    background: linear-gradient(
      90deg,
      var(--tm-c-brand) 0%,
      var(--tm-c-brand-gradient) 100%
    );
  }

  .countdownVariant--left {
    background: linear-gradient(
      90deg,
      var(--tm-c-brand-gradient) 0%,
      var(--tm-c-brand) 100%
    );
  }

  .countdownVariant--center {
    background: radial-gradient(
      circle,
      var(--tm-c-brand-gradient) 0%,
      var(--tm-c-brand) 100%
    );
  }
}

@media only screen and (min-width: 1401px) {
  .countdownGridElement--left {
    margin-left: 145px;
  }
  .countdownGridElement--right {
    margin-right: 145px;
  }
}

.countdownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 810px) {
  .countdownContainer {
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width: 961px) {
  .countdownContainer--right {
    justify-content: end;
  }

  .countdownContainer--left {
    justify-content: start;
  }

  .countdownContainer--center {
    justify-content: center;
  }
}

.countdownWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
@media only screen and (min-width: 360px) {
  .countdownWrapper {
    flex-direction: row;
  }
}

.countdown {
  display: flex;
  width: fit-content;
}
@media only screen and (min-width: 810px) {
  .countdown {
    gap: 1rem;
  }
}

.countdownTime {
  text-align: center;
  display: flex;
  gap: 1rem;
}

.countdownTitle {
  font-size: var(--tm-fs-s);
  margin-bottom: 1.2rem;
}
@media only screen and (min-width: 810px) {
  .countdownTitle {
    font-size: 1.7rem;
    margin-right: 3.5rem;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 961px) {
  .countdownTitle {
    font-size: var(--tm-fs-base);
  }
}

.timeContainer {
  width: 40px;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 810px) {
  .timeContainer {
    width: 50px;
  }
}

.timeValue {
  font-size: var(--tm-fs-base);
}
@media only screen and (min-width: 810px) {
  .timeValue {
    font-size: var(--tm-fs-m);
    max-height: 3rem;
  }
}

.timeUnit {
  font-size: 1.2rem;
}
@media only screen and (min-width: 810px) {
  .timeUnit {
    font-size: var(--tm-fs-s);
  }
}
