.courseTabs {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  list-style: none;
  margin: 6rem 0;
  padding: 0;
  flex-wrap: wrap;
}
@media only screen and (min-width: 961px) {
  .courseTabs {
    flex-wrap: unset;
  }
}

.courseTab {
  background-color: var(--tm-c-dark-gray);
  padding: 1.5rem 0;
  border-radius: 4px;
  flex-grow: 1;
  flex-basis: 31%;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}
@media only screen and (min-width: 961px) {
  .courseTab {
    padding: 2rem 0;
  }
  .courseTab:hover {
    background-color: var(--tm-c-base-dark);
  }
}
.courseTabActive {
  background-color: var(--tm-c-base-dark);
}

.courseWrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.courseOverview {
  display: flex;
  gap: 2rem;
  opacity: 0;
  grid-row: 1;
  grid-column: 1;
  transition: opacity 0.3s;
  flex-direction: column;
  pointer-events: none;
}
@media only screen and (min-width: 961px) {
  .courseOverview {
    flex-direction: row;
  }
}
.courseOverviewActive {
  opacity: 1;
}

.courseMedia {
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}
@media only screen and (min-width: 461px) {
  .courseMedia {
    height: 300px;
  }
}
@media only screen and (min-width: 661px) {
  .courseMedia {
    height: 400px;
  }
}
@media only screen and (min-width: 961px) {
  .courseMedia {
    width: 40%;
    height: unset;
  }
}

.courseContent {
  width: 100%;
  padding: 2rem;
  background: var(--tm-c-dark-gray);
  border-radius: 1rem;
}
@media only screen and (min-width: 961px) {
  .courseContent {
    width: 60%;
    padding: 6rem;
  }
}
.courseContent h3 {
  margin: 0 0 1rem;
  color: var(--tm-c-brand);
  font-weight: 400;
  font-size: var(--tm-fs-base);
}
.courseContent h2 {
  margin: 0;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.2;
}

.courseRoundDate {
  margin: 0 0 4rem;
}

.courseInfo {
  font-size: var(--tm-fs-s);
  margin: 0;
}

.courseContentListWrapper {
  display: flex;
  gap: 2rem;
  margin-top: 6rem;
}

.courseContentList {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
@media only screen and (min-width: 1401px) {
  .courseContentList {
    width: 70%;
  }
}

.courseContentList li {
  opacity: 0.6;
  font-size: var(--tm-fs-s);
  margin-top: 0.5rem;
  display: flex;
  gap: 2rem;
}

.courseContentList li:first-of-type {
  opacity: 1;
  margin: 0;
}

.courseContentList li span {
  display: block;
  width: 100%;
}
