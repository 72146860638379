.loading {
  display: inline-block;
  height: 1.5rem;
  background: var(--tm-c-dark-gray);
  opacity: 0.6;
  width: 20px;
  border-radius: 10px;
}

.playerProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2.8rem 0.8rem 0 2rem;
}
@media only screen and (min-width: 861px) {
  .playerProfile {
    margin: 5rem 5rem 0 10rem;
  }
}

.playerDetails {
  display: flex;
  gap: 1.5rem;
}

.playerPicture {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  background: var(--tm-c-dark-gray);
  margin: auto;
}
@media only screen and (min-width: 961px) {
  .playerPicture {
    width: 50px;
    height: 50px;
  }
}

.playerInfoLoading {
  min-width: 200px;
}

.playerInfo {
  max-width: 140px;
}
@media only screen and (min-width: 961px) {
  .playerInfo {
    max-width: 100%;
  }
}
.playerInfo h3 {
  font-size: var(--tm-fs-base);
  font-weight: 400;
  margin: 0;
}
.playerInfo p {
  font-size: var(--tm-fs-s);
  margin: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.scoreCardContainer {
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
  overflow: hidden;
  flex-direction: column;
}
@media only screen and (min-width: 1311px) {
  .scoreCardContainer {
    margin: 1rem 5rem 4rem 5rem;
    flex-direction: row;
    gap: unset;
  }
}
.scoreCardContainer > * {
  background: #343434;
  width: 100%;
}
.scoreCard {
  display: flex;
  font-size: 1rem;
}
@media only screen and (min-width: 441px) {
  .scoreCard {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 861px) {
  .scoreCard {
    font-size: var(--tm-fs-s);
  }
}
.scoreCardFront,
.scoreCardBack {
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 1311px) {
  .scoreCardFront {
    border-radius: 10px 0 0 10px;
  }
  .scoreCardBack {
    border-radius: 0 10px 10px 0;
  }
  .scoreCardFrontNineRound,
  .scoreCardBackNineRound {
    border-radius: 10px;
  }
}
.scoreCardCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
}
.scoreCardCol:hover {
  background-color: rgba(var(--tm-c-brand-rgb), 0.4);
}
.scoreCardCol:last-of-type:hover {
  background: none;
  cursor: default;
}
.scoreCardColSelected {
  background: rgba(var(--tm-c-brand-rgb), 0.8);
}
.scoreCardCol > * {
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scoreCardCol > *:first-child {
  height: 40px;
}
@media only screen and (min-width: 861px) {
  .scoreCardCol > * {
    min-height: 40px;
  }
  .scoreCardCol > *:first-child {
    height: 56px;
  }
}
@media only screen and (min-width: 961px) {
  .scoreCardCol > * {
    min-height: 44px;
  }
}
.scoreCardCol > *:last-of-type {
  background: rgba(118, 118, 118, 0.5);
}
.scoreCardColHighlight,
.scoreCardColHighlight:hover {
  cursor: default;
  background: rgba(118, 118, 118, 0.5);
}
.scoreCardColEnd span {
  opacity: 0;
}
@media only screen and (min-width: 1311px) {
  .scoreCardColEnd,
  .scoreCardColBack {
    display: none;
  }
}

.scoreCardHoleNumber {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  padding-top: 1rem;
}
.scoreCardHoleNumber > * {
  line-height: 1;
}
.scorCardHoleNumberIcon {
  font-size: 1.2rem !important;
}
@media only screen and (min-width: 861px) {
  .scorCardHoleNumberIcon {
    font-size: 2rem !important;
  }
}

.scoreCardLegend {
  display: flex;
  font-size: 1.2rem;
  margin: 3rem 1rem 0;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
}
@media only screen and (min-width: 861px) {
  .scoreCardLegend {
    margin: 0 5rem;
    gap: 2rem;
    flex-wrap: unset;
  }
}
.legendWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: calc(50% - 0.5rem);
  height: fit-content;
}
@media only screen and (min-width: 861px) {
  .legendWrapper {
    width: unset;
  }
}
.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.legend > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 1;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 861px) {
  .legend {
    width: 2rem;
    height: 2rem;
  }
}

@media only screen and (min-width: 861px) {
  .scoreCardCol > *:last-of-type .legend {
    width: 2.2rem;
    height: 2.2rem;
  }
  .scoreCardCol > *:last-of-type .legendEagle,
  .scoreCardCol > *:last-of-type .legendDBogey {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.legendEagle {
  border-radius: 50%;
  border: 1px solid var(--tm-c-base-light);
  overflow: hidden;
  padding: 2px;
  width: 2rem;
  height: 2rem;
}

@media only screen and (min-width: 861px) {
  .legendEagle {
    width: 2.2rem;
    height: 2.2rem;
  }
}
.legendEagle > span {
  background: var(--tm-c-brand);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  line-height: 1;
}
@media only screen and (min-width: 861px) {
  .legendEagle > span {
    line-height: 18px;
  }
}
.legendBirdie {
  border-radius: 50%;
  background: var(--tm-c-brand);
}
.legendBogey {
  background: var(--tm-c-dark);
}
.legendDBogey {
  border: 1px solid var(--tm-c-dark);
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  padding: 2px;
}
@media only screen and (min-width: 861px) {
  .legendDBogey {
    width: 2.2rem;
    height: 2.2rem;
  }
}
.legendDBogey > span {
  background: var(--tm-c-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 1;
}

.videoContainer {
  position: relative;
}
.videoContainerAmateur {
  padding-bottom: 10rem;
}
@media only screen and (min-width: 861px) {
  .videoContainerAmateur {
    padding-bottom: 30rem;
  }
}
.videoPlaceholder {
  height: 180px;
  margin: 0;
  width: 100%;
  background: rgba(65, 65, 65, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 861px) {
  .videoPlaceholder {
    height: 280px;
  }
}
@media only screen and (min-width: 961px) {
  .videoPlaceholder {
    height: 380px;
  }
}
@media only screen and (min-width: 1311px) {
  .videoPlaceholder {
    height: 580px;
    margin: 0 2rem;
    width: calc(100% - 4rem);
  }
}
.videoPlaceholderContent {
  width: 60%;
  text-align: center;
}
.videoPlaceholderContent h2 {
  color: var(--tm-c-base-dark);
  opacity: 0.6;
  font-size: var(--tm-fs-m);
  line-height: 1.2;
}
@media only screen and (min-width: 961px) {
  .videoPlaceholderContent {
    width: 40%;
  }
}

.buttonGroup {
  display: flex;

  flex-direction: column-reverse;
  gap: 0.7rem;
}
@media only screen and (min-width: 861px) {
  .buttonGroup {
    flex-direction: row;
    gap: 2rem;
  }
}

.roundButtons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
@media only screen and (min-width: 861px) {
  .roundButtons {
    gap: 2rem;
  }
}

.scorecardButton {
  background-color: #343434;
  width: fit-content;
  border-radius: 6px;
  padding: 6px 10px;
  line-height: 1;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 32px;
}
.scorecardButton:hover {
  background-color: rgba(52, 52, 52, 0.6);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.shareButtonWrapper {
  align-self: flex-end;
}
@media only screen and (min-width: 861px) {
  .shareButtonWrapper {
    align-self: flex-start;
  }
}

.shareMessage {
  position: absolute;
  background-color: rgba(52, 52, 52, 0.6);
  font-size: 1.2rem;
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: 5px;
  display: block;
  width: fit-content;
  right: 0;
}
@media only screen and (min-width: 861px) {
  .shareMessage {
    right: auto;
  }
}
