.swiper {
  margin: 4rem 0;
}
@media only screen and (min-width: 961px) {
  .swiper {
    margin: 8rem 0;
  }
}

.swiperSlide {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  border-radius: 10px;
  overflow: hidden;
  background: var(--tm-c-base-dark);
}
@media only screen and (min-width: 810px) {
  .swiperSlide {
    flex-direction: row;
    width: 750px !important;
    height: 520px !important;
  }
}

.slideMedia {
  position: relative;
  height: 300px;
}
@media only screen and (min-width: 810px) {
  .slideMedia {
    height: unset;
    width: 40%;
  }
}

.slideContent {
  padding: 4rem;
  text-align: center;
}
@media only screen and (min-width: 810px) {
  .slideContent {
    padding: 6rem;
    width: 60%;
    text-align: unset;
  }
}
.slideContent h2 {
  margin: 0 0 4rem;
  font-size: var(--tm-fs-m);
}
.slideContent p {
  margin: 0 0 2rem;
  font-size: var(--tm-fs-s);
}

.ctaButtonMargin {
  margin: 4rem auto 0;
}
@media only screen and (min-width: 810px) {
  .ctaButtonMargin {
    margin: 4rem 0 0;
  }
}

.arrow {
  position: absolute;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}

.arrowLeft {
  left: 2rem;
}

.arrowRight {
  right: 2rem;
}
@media only screen and (min-width: 810px) {
  .arrowLeft {
    left: 15rem;
  }
  .arrowRight {
    right: 15rem;
  }
}
@media only screen and (min-width: 1200px) {
  .arrowLeft {
    left: 30rem;
  }
  .arrowRight {
    right: 30rem;
  }
}
@media only screen and (min-width: 1400px) {
  .arrowLeft {
    left: 50rem;
  }
  .arrowRight {
    right: 50rem;
  }
}
@media only screen and (min-width: 1700px) {
  .arrowLeft {
    left: 70rem;
  }
  .arrowRight {
    right: 70rem;
  }
}
