.imageWrapper {
  /* max-height: 40rem; */
  position: relative;
  max-width: 400px;
}

.imageWrapper img {
  border-radius: 10px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
@media only screen and (min-width: 601px) and (max-width: 961px) {
  .contentWrapper {
    padding-left: 2rem;
  }
}
.contentWrapper h2 {
  margin: 2rem 0 0;
}
@media only screen and (min-width: 601px) {
  .contentWrapper h2 {
    font-size: 3rem;
    margin: 0;
  }
}
.contentWrapper p {
  margin: 0;
}
