@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--tm-ff);
  /* set baseline for font size to 10px - easier to calc with REM */
  font-size: 62.5%;
  font-display: fallback;
}

body {
  font-weight: 300;
  /*Theme*/
  color: var(--tm-c-base-light);
  background: var(--tm-c-dark);
}

.pro,
.amateur {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1,
h2 {
  font-size: var(--tm-fs-m);
  font-weight: 400;
}
@media only screen and (min-width: 810px) {
  h1,
  h2 {
    font-size: 3rem;
  }
}
@media only screen and (min-width: 961px) {
  h1,
  h2 {
    font-size: var(--tm-fs-l);
  }
}

a {
  opacity: 1;
  transition: opacity 0.3s;
}
@media only screen and (min-width: 961px) {
  a:hover {
    opacity: 0.6;
  }
}

#__next {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* custom styles overwrite */
.hamburger-react {
  z-index: 3;
}

/* custom swiper styles */
.swiper-slide {
  opacity: 0.3;
  height: unset;
}
.swiper-slide-active {
  opacity: 1;
}

.swiper-button-next,
.swiper-button-prev {
  width: 12px;
  height: 12px;
  border-top: 2px solid var(--tm-c-base-light);
  border-left: 2px solid var(--tm-c-base-light);
  position: absolute;
  bottom: 1rem;
  cursor: pointer;
}
.swiper-button-next {
  transform: rotate(135deg);
  right: 2rem;
}
.swiper-button-prev {
  transform: rotate(-45deg);
  left: 2rem;
}
.swiper-button-disabled {
  border-color: #343434 !important;
  color: #343434 !important;
}

@media only screen and (min-width: 961px) {
  .swiper-button-next {
    right: 25rem;
  }
  .swiper-button-prev {
    left: 25rem;
  }
}

.swiper-pagination {
  display: flex;
  gap: 0.6rem;
  width: fit-content;
  margin: 3rem auto 1rem;
  cursor: pointer;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: rgba(217, 217, 217, 0.5);
  display: block;
}
.swiper-pagination-bullet-active {
  background: #d9d9d9;
}

:root {
  /* font family */
  --tm-ff: Poppins, -apple-system, Helvetica Neue, sans-serif;

  /* font size */
  --tm-fs-s: 1.5rem;
  --tm-fs-base: 2rem;
  --tm-fs-m: 2.5rem;
  --tm-fs-l: 4rem;

  /* color */
  --tm-c-brand: #ec691a;
  --tm-c-brand-rgb: 236, 105, 26;
  --tm-c-base-light: #fff;
  --tm-c-base-dark: #414141;
  --tm-c-dark-gray: #141414;
  --tm-c-dark: #090909;
  --tm-c-brand-gradient: #7f3e17;
}

.amateur {
  --tm-c-brand: #00c600;
  --tm-c-brand-rgb: 0, 198, 0;
  --tm-c-brand-gradient: #125d15;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
