.faqGrid {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1401px) {
  .faqGrid {
    margin-top: 7rem;
    margin-bottom: 4rem;
  }
}

.faqTitle {
  margin-top: 0;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1401px) {
  .faqGrid {
    margin-bottom: 3rem;
  }
}

.faqCollapsibleItem {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media only screen and (min-width: 1401px) {
  .faqCollapsibleItem {
    gap: 1.5rem;
  }
}

.faqCollapsibleContainer {
  cursor: pointer;
  border-radius: 10px;
  background: #343434;
  padding: 1.5rem 3rem 1.5rem 3.5rem;
}

.faqCollapsibleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: var(--tm-fs-s);
  letter-spacing: 0.45px;
  font-weight: 400;
}
@media only screen and (min-width: 961px) {
  .faqCollapsibleHeader {
    font-size: var(--tm-fs-base);
    letter-spacing: 0.6px;
  }
}

.faqHeaderIcon {
  display: flex !important;
  font-size: 3.5rem !important;
}

.faqCollapsibleContent {
  height: 0;
  overflow: hidden;
  background: #343434;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.faqCollapsibleContent p {
  font-size: var(--tm-fs-s);
}
@media only screen and (min-width: 1201px) {
  .faqCollapsibleContent p {
    font-size: 1.8rem;
    letter-spacing: 0.6px;
  }
}

.faqLink {
  color: var(--tm-c-brand);
  text-decoration: none;
}
