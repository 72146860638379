.checkboxControl {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.checkboxControl span {
  font-size: 1.7rem;
  cursor: pointer;
}

.checkboxControl input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--tm-c-brand);
  border-radius: 3px;
  display: grid;
  place-content: center;
  cursor: pointer;
  flex:none
}

.checkboxControl input[type="checkbox"]::before {
  content: "";
  width: 1rem;
  height: 1rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.checkboxControl input[type="checkbox"]:checked::before {
  background: var(--tm-c-brand);
  transform: scale(1);
}

.checkboxControl input[type="checkbox"]:disabled,
.checkboxControl input[type="checkbox"]:disabled + span {
  opacity: 0.3;
  cursor: default;
}
