.textWithMedia {
  display: grid;
  grid-template-columns: 1fr;
}
.textWithMedia > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.bgGraphic {
  display: none;
}
@media only screen and (min-width: 961px) {
  .bgGraphic {
    display: block;
    pointer-events: none;
  }
}

.media {
  position: relative;
  height: 350px;
  margin-top: 4rem;
  overflow: hidden;
}
@media only screen and (min-width: 810px) {
  .media {
    width: 540px;
    height: 500px;
    margin: 2rem auto;
  }
}
@media only screen and (min-width: 961px) {
  .media {
    width: auto;
    height: auto;
    margin: unset;
    grid-column: 2 / span 8;
    grid-row: 1;
  }
  .mediaReverse {
    grid-column: 8 / span 8;
  }
}
@media only screen and (min-width: 1401px) {
  .media {
    grid-column: 3 / span 16;
    height: 800px;
  }
  .mediaReverse {
    grid-column: 15 / span 16;
  }
}

.media > span {
  width: inherit !important;
  height: inherit !important;
}

.media img {
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 2rem;
}
@media only screen and (min-width: 661px) {
  .content {
    grid-column: 2 / span 2;
  }
}
@media only screen and (min-width: 961px) {
  .content {
    margin-top: unset;
    grid-column: 11 / span 5;
    grid-row: 1;
    align-items: initial;
    text-align: initial;
  }
  .contentReverse {
    grid-column: 2 / span 5;
  }
}
@media only screen and (min-width: 1401px) {
  .content {
    grid-column: 21 / span 10;
  }
  .contentReverse {
    grid-column: 3 / span 10;
  }
}

.content h2 {
  line-height: 1.2;
  margin: 0;
}
.content p {
  margin: 0 0 2rem;
  line-height: 1.6;
}

.content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 1201px) {
  .content ul {
    width: 50%;
  }
}
.content ul li {
  margin-bottom: 0.5rem;
  display: flex;
  gap: 2rem;
}
.content ul li:last-of-type {
  margin-bottom: 0;
}
.content ul li > * {
  display: block;
  width: 100%;
}
