.videoContainer {
  padding: 2rem 0 5rem;
  max-width: 1900px !important;
}
@media only screen and (min-width: 861px) {
  .videoContainer {
    padding: 0 2rem 5rem;
  }
}

.reactPlayer {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.videoNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem auto 0;
  max-width: 1200px;
}
.videoShotNumber {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  background: var(--tm-c-brand);
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: var(--tm-fs-s);
}
@media only screen and (min-width: 861px) {
  .videoShotNumber {
    min-width: 2.6rem;
    height: 2.6rem;
  }
}
.videoShotNumber:hover {
  opacity: 0.6;
}
.videoShotNumberSelected {
  opacity: 0.6;
}
.shotTimeline {
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.6);
}
.shotTimelineStatus {
  height: 5px;
  background: var(--tm-c-brand);
  max-width: 100%;
}
.shotTimelineStatusComplete {
  width: 100%;
}
