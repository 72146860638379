.gridDefault {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 961px) {
  .gridDefault {
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 1401px) {
  .gridDefault {
    margin-bottom: 3rem;
  }
}

.textBoxLayout {
  padding: 40px 30px;
  border-radius: 10px;
  font-size: var(--tm-fs-s);
}
@media only screen and (min-width: 961px) {
  .textBoxLayout {
    padding: 60px 80px;
  }
}
@media only screen and (min-width: 1401px) {
  .textBoxLayout {
    font-size: var(--tm-fs-base);
  }
}

.unorderedList {
  padding-inline-start: 20px;
}

.twoColumnLayout {
  display: grid;
  gap: 20px;
}
@media only screen and (min-width: 810px) {
  .twoColumnLayout {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}
@media only screen and (min-width: 1401px) {
  .twoColumnLayout {
    gap: 30px;
  }
}

.textBoxTitle {
  font-size: var(--tm-fs-base);
  margin: 0 0 20px;
}
@media only screen and (min-width: 961px) {
  .textBoxTitle {
    font-size: var(--tm-fs-m);
    margin: 0 0 35px;
  }
}
