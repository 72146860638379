.videoWrapper {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}
.videoWrapperPortrait {
  height: 450px;
  width: 80%;
  margin: auto;
}
.videoWrapperOverlay {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: none;
}
.videoWrapper:hover .videoWrapperOverlay {
  opacity: 0.6;
}
@media only screen and (min-width: 531px) {
  .videoWrapper {
    height: 300px;
  }
  .videoWrapperPortrait {
    height: 500px;
    width: 350px;
  }
}
@media only screen and (min-width: 751px) {
  .videoWrapper {
    height: 350px;
  }
  .videoWrapperPortrait {
    height: 500px;
  }
}
@media only screen and (min-width: 1201px) {
  .videoWrapper {
    height: 400px;
  }
}
@media only screen and (min-width: 1601px) {
  .videoWrapper {
    height: 450px;
  }
}

.contentWrapper {
  margin: auto 0 4rem;
  text-align: center;
}
@media only screen and (min-width: 961px) {
  .contentWrapper {
    text-align: left;
    margin: auto 0;
  }
}

.contentTitle {
  margin: 0 0 2rem;
}

.contentWrapper p {
  margin: 0 0 2rem;
}
.contentWrapper p:last-of-type {
  margin: 0;
}
.contentWrapper a {
  margin-top: 2rem;
}

.portalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 3;
}
.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: auto;
  z-index: 3;
  background: rgba(0, 0, 0, 0.8);
}
.portal > div {
  padding-top: 56.25%;
  position: relative;
}
.portal > div > div {
  position: absolute;
  top: 0;
}

.playButton,
.closeIcon {
  cursor: pointer;
  transition: color 0.3s;
}

.playButton {
  color: rgba(255, 255, 255, 0.6);
  z-index: 1;
}
.videoWrapper:hover .playButton,
.playButton:hover {
  color: rgba(255, 255, 255, 0.3);
}

.closeIcon {
  color: rgba(255, 255, 255, 0.8);
  top: -30px;
  right: -30px;
  z-index: 2;
  position: absolute;
}

.videoGridContent {
  display: flex;
  margin: 0 auto;
}

.cta {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 961px) {
  .cta {
    margin-left: unset;
    margin-right: unset;
  }
}
