.navGrid > div {
  margin: 0;
}
@media only screen and (min-width: 1201px) {
  .navGrid > div {
    margin: auto;
  }
}

.navContainer {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1201px) {
  .navContainer {
    gap: 9rem;
  }
}

.navHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: var(--tm-c-dark);
  padding: 1.7rem 2.5rem;
  z-index: 3;
}
@media only screen and (min-width: 961px) {
  .navHeader {
    padding: 1.7rem 0rem;
  }
}
@media only screen and (min-width: 1201px) {
  .navHeader {
    padding: 4.5rem 0;
    width: fit-content;
    justify-content: unset;
  }
}

.navLogoContainer {
  position: relative;
  width: 105px;
  height: 45px;
}

.mainNav {
  background: var(--tm-c-dark);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  overflow-y: auto;
  font-size: var(--tm-fs-s);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 15px;
}
@media only screen and (min-width: 961px) {
  .mainNav {
    width: 465px;
  }
}
@media only screen and (min-width: 1201px) {
  .mainNav {
    color: var(--tm-c-base-light);
    background: none;
    position: static;
    width: unset;
    height: unset;
    padding: unset;
    overflow-y: unset;
    align-items: center;
  }
}

.mainNavItemWrapper {
  display: flex;
  flex-direction: column;
  padding: 16rem 3.5rem 10rem;
}
@media only screen and (min-width: 1201px) {
  .mainNavItemWrapper {
    flex-direction: row;
    padding: 0;
  }
}

.seriesToggle {
  display: flex;
  gap: 1rem;
  margin-top: 10rem;
  text-transform: uppercase;
}
@media only screen and (min-width: 961px) {
  .seriesToggle {
    display: flex;
    gap: 1rem;
    margin-right: 8rem;
    margin-top: unset;
  }
}
.toggleItem {
  letter-spacing: 2px;
  line-height: 1;
  padding: 1rem 2rem;
  background: #212121;
  height: fit-content;
  border-radius: 6px;
  color: #767676;
}
.toggleItemSelected {
  color: var(--tm-c-brand);
}

.navItem {
  color: var(--tm-c-base-light);
  cursor: pointer;
  text-wrap: nowrap;
}

.navItemAction {
  color: var(--tm-c-brand);
}
@media only screen and (min-width: 1201px) {
  .navItem:hover {
    opacity: 1 !important;
  }
  .navItemActive,
  .navItemActive:hover {
    opacity: 0.6 !important;
  }
}

.navItemBorder {
  display: none;
}
@media only screen and (min-width: 961px) {
  .navItemBorder {
    display: flex;
    height: 2px;
    width: 100%;
    margin-top: 5px;
  }

  .borderLeft,
  .borderRight {
    width: 100%;
    display: flex;
  }
  .borderLeft .border,
  .borderRight .border {
    display: block;
    height: 2px;
    background: var(--tm-c-brand);
    width: 0%;
    transition: width 0.3s, opacity 0.3s;
  }
  .borderLeft {
    justify-content: flex-end;
  }
  .navItem:hover .borderLeft .border,
  .navItem:hover .borderRight .border,
  .navItemActive .borderLeft .border,
  .navItemActive .borderRight .border {
    width: 100%;
  }
}

.footer {
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
  background: var(--tm-c-dark-gray);
  z-index: 1;
}
.footer > * {
  font-size: var(--tm-fs-s);
}

.footerNav .navItem,
.socialNav .navItem {
  transform: unset !important;
}

.footerNav {
  display: flex;
  margin: 0 auto 8rem;
  gap: 2rem;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.footerNav .navItem {
  transition: opacity 0.3s;
  font-size: var(--tm-fs-s) !important;
  opacity: 1 !important;
}
.footerNav .navItem:first-of-type {
  margin: 0 !important;
}
.footerNav .navItem:last-of-type {
  margin: 0 !important;
}
.footerNav .navItem:hover {
  opacity: 0.6 !important;
}
@media only screen and (min-width: 961px) {
  .footerNav {
    flex-direction: row;
    gap: 4rem;
  }
}

.socialNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 4rem;
  gap: 2rem;
}
.socialNav .navItem {
  opacity: 1 !important;
  margin: 0 !important;
}

.footer p.footerCopy {
  margin: 0;
}

.footer .seriesToggleWrapper {
  width: fit-content;
  margin: 0 auto 6rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
}

.footer .seriesToggleWrapper .seriesToggle {
  margin-top: 0;
  margin-right: 0;
}
