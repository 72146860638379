.blogPostsGridWrapper {
  margin-top: 30px;
}
@media only screen and (min-width: 961px) {
  .blogPostsGridWrapper {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1401px) {
  .blogPostsGridWrapper {
    margin-top: 110px;
  }
}

.blogPostsTitle {
  font-size: var(--tm-fs-m);
  margin-bottom: 10px;
  text-align: center;
}

.blogPostsSmallLink {
  display: flex;
  width: 100%;
}

.blogPostsSmall {
  display: grid;
  gap: 1.5rem;
  margin-top: 4rem;
}

@media only screen and (min-width: 810px) {
  .blogPostsSmall {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .blogPostsSmall {
    grid-auto-flow: column;  
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (min-width: 1401px) {
  .blogPostsSmall {
    gap: 3rem;
    margin-top: 2.5rem;
  }
}

.blogPostsMargin {
  margin: 20px auto 0;
}
@media only screen and (min-width: 961px) {
  .blogPostsMargin {
    margin: 35px auto 0;
  }
}
