.root > * {
  margin: 0 auto;
}

.leaderboardTable {
  background: #2c2c2c;
  border-radius: 10px;
  overflow: hidden;
  min-height: 800px;
}

.tableHeader {
  background-color: var(--tm-c-base-dark);
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  gap: 0.5rem;
}
@media only screen and (min-width: 861px) {
  .tableHeader {
    gap: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .tableHeader {
    padding: unset;
  }
}

.tableHeaderCell {
  padding: 1.5rem 0;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}
.tableHeaderCell:nth-of-type(3) {
  flex: 4;
  text-align: left;
}
.tableHeaderCell:nth-of-type(4) {
  flex: 2;
}
@media only screen and (min-width: 861px) {
  .tableHeaderCell:nth-of-type(3) {
    flex: 5;
  }
  .tableHeaderCell:nth-of-type(4) {
    flex: 1;
  }
}
@media only screen and (min-width: 961px) {
  .tableHeaderCell {
    font-size: var(--tm-fs-s);
  }
}

.thCellLabel {
  display: none;
}
@media only screen and (min-width: 961px) {
  .thCellLabel {
    display: block;
  }
}

.tableRowGroup {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s;
}

.leaderboardTable .tableRowGroup {
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
  border-bottom: 1px solid var(--tm-c-base-dark);
}
@media only screen and (min-width: 961px) {
  .leaderboardTable .tableRowGroup:hover {
    background-color: #171717;
  }
}

.leaderboardTable .tableRowGroup:last-of-type {
  border-bottom: none;
}

.tableRowGroup > .tableRow {
  padding: 0 1rem;
}
@media only screen and (min-width: 961px) {
  .tableRowGroup > .tableRow {
    padding: unset;
  }
}

.tableRowGroup .tableRowGroupInner {
  overflow: hidden;
  cursor: default;
}
.tableRowGroupActive .tableRowGroupInner {
  background: #212121;
}

.tableRowGroupInnerContent {
  background: #343434;
  margin: 2rem;
  border-radius: 10px;
  overflow: hidden;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
}
@media only screen and (min-width: 861px) {
  .tableRow {
    gap: 1rem;
  }
}

.tableRowCell {
  padding: 1.5rem 0;
  text-align: center;
  flex: 1;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media only screen and (min-width: 861px) {
  .tableRowCell {
    font-size: var(--tm-fs-s);
  }
}
.tableRowCell:nth-of-type(3) {
  flex: 4;
  text-align: left;
  justify-content: flex-start;
}
.tableRowCell:nth-of-type(4) {
  flex: 2;
}
@media only screen and (min-width: 861px) {
  .tableRowCell:nth-of-type(3) {
    flex: 5;
  }
  .tableRowCell:nth-of-type(4) {
    flex: 1;
  }
}
.tableRowGroupInner .tableRowCell {
  flex: 1;
  text-align: center;
  justify-content: center;
}

.tableRowGroupInner .tableHeader {
  padding: unset;
}
