.swiperWrapper {
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 961px) {
  .swiperWrapper {
    width: 850px !important;
  }
}

.testimonialsGridWrapper {
  padding: 40px 10px;
}
@media only screen and (min-width: 961px) {
  .testimonialsGridWrapper {
    padding: 90px auto;
  }
}

.slide {
  border-radius: 10px;
  background: var(--colors-box-primary, #212121);
  box-shadow: 7px 10px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  height: fit-content;
  display: flex;
  align-self: center;
}
@media only screen and (min-width: 961px) {
  .slide {
    padding: 50px 70px;
    box-shadow: 20px 10px 44px 0px rgba(0, 0, 0, 0.15);
  }
}

.slideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  text-align: center;
}
@media only screen and (min-width: 961px) {
  .slideContainer {
    flex-direction: row;
    align-items: initial;
    text-align: left;
  }
}

.slideContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.slideImage {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
@media only screen and (min-width: 961px) {
  .slideImage {
    width: 120px;
    height: 120px;
  }
}

.testimonialsContent {
  font-size: var(--tm-fs-s);
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.45px;
  font-weight: 400;
}
@media only screen and (min-width: 961px) {
  .testimonialsContent {
    font-size: var(--tm-fs-base);
    letter-spacing: 0.6px;
  }
}

.testimonialsName {
  font-size: var(--tm-fs-s);
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.arrow {
  position: absolute;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}

.arrowLeft {
  left: 2rem;
}

.arrowRight {
  right: 2rem;
}

@media only screen and (min-width: 961px) {
  .arrowRight {
    right: 25rem;
  }
  .arrowLeft {
    left: 25rem;
  }
}
